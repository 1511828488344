import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { TabItem } from '../../types';

import Sidebar from '../Sidebar';
import './style.css';
import Banner from "../Banner";

type IProps = {
    title?: string;
    full?: boolean;
    mainClass?: string;
    sample_id?: string;
    onCancel?: () => void;
    hasClose?: boolean;
    back?: TabItem;
    children?: any;
}

const Page: FC<IProps> = (props: IProps) => {
    const history = useHistory();

    let onCancel = undefined;
    if (props.onCancel) {
        onCancel = props.onCancel;
    } else if (props.back !== undefined) {
        onCancel = () => history.push("/", { tab: props.back })
    }

    return (
        <div className={props.full ? 'container-full' : 'container'}>
            {!props.full &&
                <Sidebar />
            }
            <div className={props.mainClass ? `page ${props.mainClass}` : 'page'} style={{ backgroundColor: props.full ? '#FFFFFF' : '#F5F5F5' }}>
                {!props.full && <Banner/> }{!props.full &&
                    <div className="content">
                        {props.children}
                    </div>
                }
                {props.full &&
                    <div className="content-full">
                        <div className="fixed-header">
                            {props.title &&
                                <h2 className="title center">
                                    {props.title}
                                    {props.sample_id &&
                                        <>&nbsp;<span className="sample_id">{props.sample_id}</span></>
                                    }
                                </h2>
                            }
                            {onCancel &&
                                <button className={props.hasClose ? 'cancel closeBtn' : 'btn btn3 cancel'} onClick={onCancel}>
                                    {props.hasClose ? <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1.71214L15.2879 0L8.5 6.78786L1.71214 0L0 1.71214L6.78786 8.5L0 15.2879L1.71214 17L8.5 10.2121L15.2879 17L17 15.2879L10.2121 8.5L17 1.71214Z" fill="#373535" />
                                    </svg>
                                        : 'Cancel'}
                                </button>
                            }
                            <hr />
                        </div>
                        <div className="full-content text-body" id="topView">
                            {props.children}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Page;
