import React, {FC} from 'react';
import { Route, Switch } from 'react-router-dom';

import { Page } from '../../Components';
import { ManageSamplesAnalysis, ReportAnalysisData } from '..';
import ConfirmReceiptSamplesRoute from '../ConfirmReceiptSamples/route';
import SampleDetailViewAnalysis from '../SampleDetailView/analysis';
import DisposeSampleRoute from '../DisposeSample/route';
import PrepareTransportRoute from '../PrepareTransport/route';

const AnalysisRoutes: FC = () => {
    return (
        <Switch>
            <Route path="/prepare-for-transport">
                <PrepareTransportRoute />
            </Route>
            <Route path="/report/:sample_id" exact>
                <ReportAnalysisData />
            </Route>
            <Route path="/result/:sample_id" exact>
                <SampleDetailViewAnalysis />
            </Route>
            <Route path="/dispose-samples" exact>
                <DisposeSampleRoute />
            </Route>
            <Route path="/receipt-samples" exact>
                <ConfirmReceiptSamplesRoute />
            </Route>
            <Route path="/" exact>
                <Page>
                    <ManageSamplesAnalysis />
                </Page>
            </Route>
        </Switch> 
    );
}

export default AnalysisRoutes;
