import React, { FC, useState, useContext, useRef, useCallback } from 'react';
import Icons from '../../Icons';
import { useOnClickOutside } from '../../Pages/hooks';
import { StateContext } from '../../state';
import { SortOrder, SortType } from '../../types';

import './style.css';

type IProps = {
    id: string
    label: string
    type: SortType
}

const Sortable: FC<IProps> = (props: IProps) => {
    const { state: { activeTab, sort, sortLoading }, dispatch } = useContext(StateContext);
    const {column, order} = sort[activeTab] || {column: 'sample_id', order: SortOrder.ASC}
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = useRef<any>()
    useOnClickOutside(ref, () => setIsOpen(false))

    const { id, label, type } = props
    const isActive = column === id

    const toggleOpen = () => setIsOpen(old => !old)
    const setSort = useCallback((value: SortOrder) => {
        if (sortLoading) return
        dispatch({ type: "setSort", payload: { column: id, order: value } })
        setIsOpen(false)
    }, [sortLoading, dispatch, id])

    return (
        <div className="sortable" ref={ref}>
            {isActive ? <Icons.ActiveSort onClick={toggleOpen} /> : <Icons.DefaultSort onClick={toggleOpen} />}
            {isOpen &&
                <ul className="popmenu">
                    <li onClick={() => setSort(SortOrder.ASC)}><span>{label}: {type === SortType.Alphabet ? 'A-Z' : 'Oldest-Newest'}</span> <Icons.BlackCheck style={{ opacity: isActive && order === SortOrder.ASC ? 1 : 0 }} /></li>
                    <li onClick={() => setSort(SortOrder.DESC)}><span>{label}: {type === SortType.Alphabet ? 'Z-A' : 'Newest-Oldest'}</span> <Icons.BlackCheck style={{ opacity: isActive && order === SortOrder.DESC ? 1 : 0 }} /></li>
                </ul>
            }
        </div>
    );
}

export default Sortable;
