import React, {FC} from 'react';
import moment from 'moment';

import {SampleStatuses} from '../../types';
import {GetSampleStatus, SampleStatusByLabel} from '../../helpers';
import './style.css';

type IProps = {
    sample: any;
    hideUpdate?: boolean;
}

const SampleStatus: FC<IProps> = (props: IProps) => {
    const {state, report_state, last_update, shared_at, further_analysis_from, not_analyzed = false} = props.sample;
    const hideUpdate = !props.hideUpdate ? false : true;
    
    let status = state;
    if (typeof status === 'string') {
        status = SampleStatusByLabel(status);
        if (status === undefined) return <>Undefined</>
    }
    const {Label, Icon} = GetSampleStatus(status, report_state, shared_at, further_analysis_from, not_analyzed);
    const isDraft: boolean = report_state === "ANALYSIS_DRAFTED" && [SampleStatuses.UndergoingAnalysis, SampleStatuses.FurtherAnalysis].includes(status);

    return (
        <div className="samplestatus">
            <Icon />
            <div className="right">
                <p className="spzero text-body">{isDraft && <span>Draft: </span>}{Label}</p>
                {last_update && !hideUpdate &&
                    <p className="spzero update">Updated {moment(last_update).fromNow()}</p>
                }
            </div>
        </div>
    );
}

export default SampleStatus;
