import React, {FC, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import CollectionSurvey from '.';
import { Page } from '../../Components';

const CollectionSurveyRoute: FC = () => {
    const [collectionServeyTitle, setCollectionServeyTitle] = useState<string>("Collection Survey");
    const [step, setStep] = useState<number>(0);
    const [route, setRoute] = useState<string>('');
    const history = useHistory();

    useEffect(() => {
        switch(step) {
            case 6:
                setCollectionServeyTitle("Submit Collection Survey");
            break;
            case 7:
                setCollectionServeyTitle("Sample Details");
            break;
            default:
                setCollectionServeyTitle("Collection Survey");
        }
    }, [step])

    let onCancel = undefined;
    if (route.length > 0) {
        onCancel = () => history.replace(route);
    } else if (step !== 7) {
        onCancel = () => history.replace("/");
    }

    return (
        <Page full title={collectionServeyTitle} onCancel={onCancel}>
            <CollectionSurvey onStep={(s: number) => setStep(s)} onCancelRoute={(r: string) => setRoute(r)} />
        </Page>
    )
}

export default CollectionSurveyRoute;
