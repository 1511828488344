import React, {FC} from 'react';

import Icons from '../../Icons';
import './style.css';

type IProps = {
    page: number;
    perPage: number;
    total: number;
    onPageChange: (page: number) => void;
    disabled?: boolean;
}

const Paginator: FC<IProps> = (props: IProps) => {
    const {page, perPage, total, onPageChange} = props;
    const disabled = !!props.disabled;

    const pages = Math.ceil(total / perPage);
    const start = (page < 2) ? 1 : ((page - 1) * perPage)
    const _end = start + perPage
    const end = (page < 2) ? perPage : _end > total ? total : _end;
    const hasFirst = page > 1 && pages > 1;
    const hasLast = page < pages && pages > 1;

    return (
        <ul className="pagination">
            <li className="stat">{disabled ? 'Loading...' : `${start}-${end} of ${total}` }</li>
            <li onClick={() => !disabled && hasFirst && onPageChange(1)} className={!disabled && hasFirst ? 'active' : ''}><Icons.FirstPage /></li>
            <li onClick={() => !disabled && hasFirst && onPageChange(page - 1)} className={!disabled && hasFirst ? 'active' : ''}><Icons.PrevPage /></li>
            <li onClick={() => !disabled && hasLast && onPageChange(page + 1)} className={!disabled && hasLast ? 'active' : ''}><Icons.NextPage /></li>
            <li onClick={() => !disabled && hasLast && onPageChange(pages)} className={!disabled && hasLast ? 'active' : ''}><Icons.LastPage /></li>
        </ul>
    );
}

export default Paginator;
