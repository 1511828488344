import React, {FC, useContext, useEffect, useState, useMemo} from 'react';
import { Checkbox } from '../../Components';
import { getUserFacingSampleType } from '../../helpers';
import Icons from '../../Icons';
import { reportMissingSamples } from '../../requests';
import { StateContext } from '../../state';

type IProps = {
    missingSamples: any[];
    receiving: boolean;
    onDone: (count: number) => void;
    onLoading: (status: boolean) => void;
    fullname: string | undefined;
}

const SampleMissing: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const message = useMemo(() => {
        if (props.receiving) {
            return "The following sample(s) weren't accepted. Check all samples that are missing and report to the administrator."
        }
        return "The following sample(s) weren't prepared for transport. Check all samples that are missing and report to the administrator."
    }, [props.receiving])

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (checkedSamples.length === 0) {
            props.onDone(0);
            return;
        }
        try {
            setLoading(true);
            await reportMissingSamples(checkedSamples.map(cs => cs.sample_id), props.fullname);
            props.onDone(checkedSamples.length);
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        props.onLoading(loading);
    }, [loading, props])

    return (
        <form className="receiptsamples" onSubmit={submitHandler}>
            <div className="field">
                <div className="beside">
                    <Icons.Alert stroke="red" />
                    <label className="label">
                        {message}
                    </label>
                </div>
                <div className="box CAMH">
                    {props.missingSamples.map((sample: any, index: number) => {
                        return (
                            <div key={index} className="boxrow">
                                <Checkbox
                                    checked={!!checkedSamples.find(cs => cs.sample_id === sample.sample_id)}
                                    onChange={(s: boolean) => {
                                        const item = checkedSamples.find(cs => cs.sample_id === sample.sample_id);
                                        if (item && !s) {
                                            setCheckedSamples(os => os.filter(o => o.sample_id !== item.sample_id));
                                        } else if (!item && s) {
                                            setCheckedSamples(os => [...os, sample]);
                                        }
                                    }} 
                                />
                                <div className="boxsample" style={{marginRight: '4.75rem'}}>
                                    <span className="sampleidlabel">Sample ID:</span>
                                    <span className="sampleid">{sample.sample_id}</span>
                                </div>
                                <div className="boxsample">
                                    <span className="sampleidlabel">Sample type:</span>
                                    <span className="sampleid">{getUserFacingSampleType(sample.sample_type)}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="field buttons">
                <button className="btn btn2" disabled={loading || checkedSamples.length === 0} type="submit" style={{marginRight: '5px'}}>Report Missing</button>
                <button className="btn btn1" disabled={loading} type="button" onClick={() => props.onDone(0)}>Skip</button>
            </div>
        </form>
    );
}

export default SampleMissing;
