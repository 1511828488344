import React, {FC} from 'react';
import Icons from '../../Icons';

import './style.css';

type IProps = {
    pickup: string;
    dropoff: string | string[];
}

const CourierRoute: FC<IProps> = (props: IProps) => {
    return (
        <div className="courier-route">
            <div className="icons">
                <Icons.Pickup />
                <div className="center">
                    <Icons.Route />
                </div>
                <Icons.Dropoff />
            </div>
            <div className="holder">
                <div className="left">
                    <div className="place">Pick up</div>
                    <div className="site">{props.pickup}</div>
                </div>
                <div className="right">
                    <div className="place">Drop off</div>
                    <div className="site">{Array.isArray(props.dropoff) ? props.dropoff.map((dp: string, index: number) => {
                        return <div key={index}>{dp}</div>
                    }) : props.dropoff}</div>
                </div>
            </div>
        </div>
    );
}

export default CourierRoute;
