import React, {FC, useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {Checkbox, CheckboxField} from '../../Components';
import {getCollectedFormFieldsServiceUser, getSampleUserService, shareResultServiceUser} from '../../requests';
import {StateContext} from '../../state';

import './style.css';
import Page from "../../Components/Page";

const ShareDetailView: FC = () => {
    const {dispatch} = useContext(StateContext);
    const [options, setOptions] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const {sample_id} = useParams<any>();
    const [useful, setUseful] = useState<string>('');
    const [plan, setPlan] = useState<any[]>([]);
    const [change_plan, setChangePlan] = useState<any[]>([]);
    const [useAgain, setUseAgain] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [isServiceUser, setIsServiceUser] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getSampleUserService(sample_id).then(res => {


            if (res.data.sample.survey_is_completed) {
                dispatch({
                    type: "showAlert", payload: {
                        error: true,
                        message: `Resharing ${sample_id} is not allowed`,
                    }
                });
                history.goBack()
            }
            const sample = res.data.sample;
            setIsServiceUser(sample.collected_from === "USER");
            getCollectedFormFieldsServiceUser().then(res => {
                const opt = {
                    ...res.data,
                    use_again: [
                        {id: 1, text: "Yes"},
                        {id: 2, text: "No"},
                        {id: 3, text: "Don't know"}
                    ],
                    had_conversation: [
                        {id: 1, text: "Yes"},
                        {id: 2, text: "No"}
                    ], change_plan: [
                        {id: 2, text: "No", value: "NO"},
                        {id: 3, text: "Don’t have any of this drug left", value: "DON'T HAVE ANY OF THIS DRUG LEFT"},
                        {id: 1, text: "Yes", value: "YES"},

                    ],
                }
                setOptions(opt);
                setLoading(false);
            })
        })

    }, [history, sample_id, dispatch]);

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setLoading(true);
            let use_again = useAgain ? useAgain.toUpperCase() : null;
            if (use_again === "DON'T KNOW") {
                use_again = "DONT_KNOW";
            }

            const form = {
                how_useful: useful,
                plan: plan.map(p => p.text),
                change_plan: change_plan.at(0)?.value,
                use_again,
                feedback: notes,
            };
            await shareResultServiceUser(sample_id, form);
            dispatch({
                type: "showAlert", payload: {
                    message: `${sample_id} Sample Status Updated`,
                    status: 'Status: Check Complete',
                }
            });
            history.goBack()
        } catch (err: any) {
            dispatch({
                type: "showAlert", payload: {
                    error: true,
                    message: err.message,
                }
            });
        } finally {
            setLoading(false);
        }
    }

    let onCancel = undefined;
    if (!loading) {
        onCancel = () => history.goBack();
    }

    const IsDisabled: boolean = loading  || (isServiceUser  === null)

    return (
        <Page

            full title="Sharing Results Survey" hasClose={window.innerWidth < 850} sample_id={sample_id}
            onCancel={onCancel}>
            <form className="sharingresults" onSubmit={submitHandler}>


                {isServiceUser &&
                    <>
                        <div className="field">
                            <label className="label">How useful are these drug checking results?</label>
                            {options.HowUsefulResults && options.HowUsefulResults.map((h: any, index: number) => {
                                return <Checkbox
                                    key={index}
                                    checked={useful === h.text}
                                    onChange={(v: boolean) => {
                                        if (v) {
                                            setUseful(h.text);
                                        } else {
                                            setUseful('');
                                        }
                                    }}
                                    label={h.text}
                                />
                            })}
                        </div>
                        <CheckboxField
                            form={change_plan}
                            question="Based on your drug checking results, do you plan to do anything different with your drug? "
                            name="change_plan"
                            updateItems={(_name: string, selectedItem: any, action: 'push' | 'remove') => {

                                if (action === 'remove') {
                                    setChangePlan([])
                                } else if (action === "push") {
                                    setChangePlan([selectedItem]);

                                }
                            }}
                            options={options.change_plan}
                        />
                        {change_plan.at(0)?.text === "Yes" && <CheckboxField
                            form={plan}
                            question="What do you plan to do with your drug? Check all that apply."
                            name="plan"
                            updateItems={(_name: string, selectedItem: any, action: 'push' | 'remove') => {
                                const index = plan.findIndex((item: any) => item.id === selectedItem.id);
                                if (index > -1 && action === 'remove') {
                                    setPlan(old => {
                                        return old.filter((_v: any, idx: number) => idx !== index);
                                    })
                                } else if (action === "push") {
                                    if (index === 0) {
                                        setPlan(old => {
                                            old[index] = selectedItem;
                                            return [...old];
                                        });
                                    } else if (index === -1) {
                                        setPlan(old => [...old, selectedItem])
                                    }
                                }
                            }}
                            options={options.DrugPlan}
                        />}
                        <div className="field">
                            <label className="label">Do you plan to use this drug checking service again?</label>
                            {options.use_again && options.use_again.map((h: any, index: number) => {
                                return <Checkbox
                                    key={index}
                                    checked={useAgain === h.text}
                                    onChange={(v: boolean) => {
                                        if (v) {
                                            setUseAgain(h.text);
                                        } else {
                                            setUseAgain('');
                                        }
                                    }}
                                    label={h.text}
                                />
                            })}
                        </div>
                    </>
                }
                <div className="field">
                    <label className="label">
                        Do you have any feedback you’d like to share?
                    </label>
                    <textarea
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        disabled={loading}
                        style={{width: window.innerWidth > 850 ? '27.875rem' : '100%'}}
                    />
                </div>
                <div className="field">
                    <button disabled={IsDisabled} className="btn btn1">Submit Feedback</button>
                </div>
            </form>
        </Page>
    );
}

export default ShareDetailView;
