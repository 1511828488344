import React, {FC, useState, useEffect, useRef, useCallback, useMemo} from 'react';

import {Option} from '../../types';
import Icons from '../../Icons';
import './style.css';

type IProps = {
    onChange: (value: any) => void;
    value?: Option;
    items: Option[];
    placeholder?: string;
    required?: boolean;
    mainClass?: string;
    nochevron?: boolean;
    canAdd?: boolean;
    searchable?: boolean;
    noItemsMessage?: string;
    notfoundError?: string;
    onPaste?: (event: any, setIsOpen: any) => void;
}

enum InputState {
    NotTouched,
    Touched
}

const Select: FC<IProps> = (props: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [showNotfoundError, setShowNotfoundError] = useState<boolean>(false);
    const [inputState, setInputState] = useState<InputState>(InputState.NotTouched);
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const componentRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLDivElement>(null);
    const activeItemRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const searchable: boolean = (props.searchable !== undefined && props.searchable === false) ? false : true;
    
    const filtered_items = useMemo(() => {
        const _filter = filter.trim().toLowerCase()
        return !props.items ? [] : props.items.filter((item: Option) => (!!props.nochevron === false || item.text.toLowerCase().startsWith(_filter) || item.alias?.toLowerCase().split(';').map((a: string) => a.trim()).find((item: string) => item.startsWith(_filter))))
    }, [props, filter]);

    useEffect(() => {
        if (props.items && props.items.length < 1) {
            props.onChange(undefined);
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (inputState === InputState.Touched && !isOpen && !props.value && !props.canAdd && props.notfoundError && props.required) {
            setShowNotfoundError(true);
        }
    }, [props.canAdd, props.notfoundError, props.required, isOpen, props.value, inputState])
    
    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if (e.shiftKey && e.key === 'Tab') {
                setIsOpen(false);
            } else if (e.key === 'Tab' && isOpen) {
                if (filtered_items[activeIndex]) {
                    props.onChange(filtered_items[activeIndex])
                }
                setIsOpen(false);
            } else if (e.key === 'Enter' && isOpen) {
                if (filtered_items.length === 1) {
                    props.onChange(filtered_items[0])
                } 
                setIsOpen(false);
            }
        }
        const handleClick = (event: any) => {
            if(componentRef && componentRef.current){
                const ref: any = componentRef.current
                if(!ref.contains(event.target)){
                    setIsOpen(false);
                    setFilter("");
                }
            }
        }
        window.addEventListener("click", handleClick);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("click", handleClick);
            window.removeEventListener("keydown", handleKeyDown);
        }
    // eslint-disable-next-line
    }, [activeIndex, filtered_items])

    const updateScroll = useCallback(() => {
        if (itemsRef && itemsRef.current && activeItemRef && activeItemRef.current) {
            itemsRef.current.scrollTop = activeItemRef.current.offsetTop - itemsRef.current.offsetTop;
        }
    }, [itemsRef, activeItemRef])

    const mainKeyDownHandler = (e: any) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setActiveIndex(old => {
                const val = old < filtered_items.length - 1 ? old + 1 : old
                return val;
            });
            updateScroll();
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setActiveIndex(old => {
                const val = old > 0 ? old - 1 : 0;
                return val;
            });
            updateScroll();
        } else if (e.key === "Enter" || (!searchable && e.key === " ")) {
            e.preventDefault();
            if (isOpen) {
                filtered_items[activeIndex] &&  props.onChange(filtered_items[activeIndex]);
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        }
    }

    const onLeave = () => {
        let changed = false;
        if (activeIndex > 0 && filtered_items[activeIndex]) {
             props.onChange(filtered_items[activeIndex]);
             changed = true;
        } else if (filter !== "") {
            const searchKey = filter.toLowerCase()
            let item = props.items.find(item => item.text.toLowerCase() === searchKey);
            if (!item) {
                item = props.items.find((d: any) => d.alias?.toLowerCase().split(";").map((a: string) => a.trim()).includes(searchKey))
                if (!item) {
                    const one_item = props.items.filter(item => item.text.toLowerCase().startsWith(searchKey.toLowerCase()));
                    if (one_item.length === 1) {
                        item = one_item[0];
                    }
                }
            }
            if (item) {
                props.onChange(item);
                changed = true;
            } else if (props.canAdd) {
                props.onChange({id: -1, text: filter})
                changed = true;
            }
        }
        if (!changed || (changed && filter === "")) {
            props.onChange(undefined);
        }
    }

    const inputKeyDownHandler = (e: any) => {
        if (props.items.length < 1) return;
        if (e.shiftKey && e.key === 'Tab') {
            e.stopPropagation();
        } else if (e.key === 'Enter' || e.key === 'Tab') {
            onLeave()
        } else if (e.key === "Escape") {
            setIsOpen(false);
            setFilter("");
        }
    }

    const showPlaceholder = !isOpen && props.items.length > 0  && (props.value===undefined || props.value?.text==='');

    return (
        <>
            <div 
                tabIndex={0} 
                onKeyDown={mainKeyDownHandler}
                className={`selectarea ${props.mainClass ? props.mainClass : ''}`}
                ref={componentRef}
                onPaste={event => props.onPaste && props.onPaste(event, setIsOpen)}
                onClick={() => {
                    if (props.items.length > 0 && !isOpen){
                        setIsOpen(true);
                        if (props.value?.text) {
                            setFilter(props.value?.text)
                            setTimeout(() => {
                                if (inputRef && inputRef.current) {
                                    inputRef.current.select();
                                }
                            }, 50)
                        }
                    }
                }}
            >
                <div className={showPlaceholder?"select placeholder":"select"} style={{cursor: searchable ? "text" :"pointer"}}>
                    {props.items.length  < 1 &&
                        <span>{props.noItemsMessage ? props.noItemsMessage : 'No item to show'}</span>
                    }
                    {isOpen && props.items.length > 0 && searchable &&
                        <input
                            className="select_text"
                            type="text"
                            value={filter}
                            placeholder={props.placeholder}
                            onChange={e => {
                                setFilter(e.target.value);
                                setShowNotfoundError(false);
                                setInputState(e.target.value.length > 0 ? InputState.Touched : InputState.NotTouched);
                                if (e.target.value.length === 0) setActiveIndex(-1);
                            }}
                            autoFocus={isOpen}
                            onKeyDown={inputKeyDownHandler}
                            onBlur={onLeave}
                            ref={inputRef}
                        />
                    }
                    {!isOpen && props.items.length > 0 &&
                        <> 
                            {props.value?.text || props.placeholder || " "}
                        </>
                    }
                    {props.items.length > 0 && !!props.nochevron === false && !isOpen &&
                        <Icons.Chevron className="chevron" />
                    }
                </div>
                {props.required && 
                    <input type="text" tabIndex={-1} name="check" required className="hidden" value={props.value?.text} />
                }
                {props.items.length > 0 && isOpen && (!searchable || (filter === "" && !!props.nochevron === false) ||(filter.length > 0 && !!props.nochevron)) &&
                    <div className="items" ref={itemsRef}>
                        {filtered_items.map((item: Option, index: number) => {
                            const active = index === activeIndex || (filter !== "" && item.text.toLowerCase() === filter.toLowerCase()) || filtered_items.length === 1;
                            return (
                                <div 
                                    ref={active ? activeItemRef : null}
                                    className={`item ${active ? 'active' : ''}`}
                                    key={index}
                                    onClick={() => {
                                        setFilter("");
                                        setIsOpen(false);
                                        props.onChange(item);
                                    }}
                                >
                                    {item.text} {item.alias && <> [{item.alias}]</>}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
            {showNotfoundError &&
                <p className="select-error">{props.notfoundError}</p>
            }
        </>
    );
}

export default Select;
