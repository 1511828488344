import React, { FC, useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import {
  NavigationBar,
  NavigationTab,
  Row,
  SearchField,
  Paginator,
  SampleStatus,
  Modal,
  Checkbox,
  Sortable,
} from "../../Components";
import {
  downloadReport,
  getCounts,
  getMySite,
  getSamples,
  setResearchParticipation,
} from "../../requests";
import { StateContext } from "../../state";
import { Count, RowBorder, SortOrder, SortType, TabItem } from "../../types";
import Icons from "../../Icons";
import {
  getCollectedFrom,
  getShareResult,
  getUserFacingSampleType,
} from "../../helpers";
import "./style.css";

let currentTab: TabItem;

const ManageSamplesCollection: FC = () => {
  const {
    state: { sort, activeTab: stateActiveTab },
    dispatch,
  } = useContext(StateContext);
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<TabItem>(
    stateActiveTab || TabItem.SamplesToTransport
  );
  const [keyword, setKeyword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [site, setSite] = useState<any>(undefined);
  const [samples, setSamples] = useState<any[]>([]);
  const [counts, setCounts] = useState<Count>();
  const [showResearchParticipationModal, setShowResearchParticipationModal] =
    useState<boolean | number>(false);
  const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const perPage = 30;

  const changeState = (
    tab: TabItem,
    page: number = 1,
    keyword: string = ""
  ) => {
    if (loading) return;
    if (tab !== currentTab) {
      currentTab = tab;
      dispatch({ type: "setActiveTab", payload: tab });
      setActiveTab(tab);
    }
    setPage(page);
    setKeyword(keyword);
  };

  const fetchCounts = async () => {
    const { data } = await getCounts();
    setCounts(data);
  };
  const fetchSite = async () => {
    const { data } = await getMySite();
    setSite(data);
  };

  const fetchSamples = async (sort: { column: string; order: SortOrder }) => {
    try {
      if (loading) return;
      setLoading(true);
      dispatch({ type: "toggleSortLoading", payload: true });
      fetchSite();
      fetchCounts();
      setSamples([]);
      const { column, order } = sort;
      const { data } = await getSamples(
        page,
        keyword,
        activeTab,
        column,
        order === 0 ? "asc" : "desc"
      );
      setTotal(data.count);
      setSamples(data.results);
    } catch (err: any) {
      dispatch({
        type: "showAlert",
        payload: {
          error: true,
          message: err.message,
        },
      });
    } finally {
      setLoading(false);
      dispatch({ type: "toggleSortLoading", payload: false });
    }
  };

  useEffect(() => {
    if (location.state) {
      const state: any = location.state;
      if (state.ResearchParticipation) {
        window.history.replaceState({}, document.title);
        setShowResearchParticipationModal(state.ResearchParticipation);
      }
      if (state.tab) {
        window.history.replaceState({}, document.title);
        changeState(state.tab);
        return;
      }
    }
    changeState(TabItem.SamplesToTransport);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    fetchSamples(sort[activeTab]);
    // eslint-disable-next-line
  }, [activeTab, page, keyword, sort]);

  const onModalClose = (value: boolean) => {
    setResearchParticipation(showResearchParticipationModal, value);
    setShowResearchParticipationModal(false);
  };

  const toggleChecked = (sample: any, checked: boolean) => {
    const has = checkedSamples.some((s) => s.sample_id === sample.sample_id);
    if (has && !checked) {
      setCheckedSamples((samples) =>
        samples.filter((s) => s.sample_id !== sample.sample_id)
      );
    } else if (!has && checked) {
      setCheckedSamples((samples) => [...samples, sample]);
    }
  };

  async function download(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    sample_id: string
  ) {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await downloadReport(sample_id);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `results_${sample_id}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (err: any) {
      dispatch({
        type: "showAlert",
        payload: {
          error: true,
          message: err.message,
        },
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {showResearchParticipationModal && (
        <Modal
          onClose={() => onModalClose(false)}
          title="Research Participation"
          ButtonNoTitle="No"
          ButtonYes={
            <a
              className="btn btn1"
              rel="noreferrer"
              target="_blank"
              href="https://form.simplesurvey.com/OSS/login.aspx"
              onClick={() => onModalClose(true)}
            >
              Yes
            </a>
          }
        >
          <p className="text-body" style={{ marginBottom: "3.75rem" }}>
            Will you answer additional questions about your substance use to
            better inform the evaluation of this service?
          </p>
        </Modal>
      )}
      <div className="samples">
        <div className="tophead">
          <div className="resources-link">
            <h2 className="title">Hi, {site?.name || ""}!</h2>
            <div className="divider">&nbsp;</div>
            <div>
              <a href="https://www.drugchecking.community" target="_blank" rel="noreferrer">
                Resources{" "}
                <Icons.ExternalLink
                  style={{ marginRight: "7px", padding: "3px" }}
                />
              </a>
            </div>
          </div>
          <button
            className="btn btn1"
            onClick={() => history.replace("/collection-survey")}
          >
            <Icons.Plus style={{ marginRight: "7px" }} />
            Add Sample
          </button>
        </div>
        <NavigationBar>
          <NavigationTab
            label="Samples To Transport"
            active={activeTab === TabItem.SamplesToTransport}
            count={counts?.to_transport}
            onClick={() => changeState(TabItem.SamplesToTransport)}
          />
          <NavigationTab
            label="Results To Share"
            active={activeTab === TabItem.ResultsToShare}
            count={counts?.to_share}
            onClick={() => changeState(TabItem.ResultsToShare)}
          />
          <NavigationTab
            label="All Samples"
            active={activeTab === TabItem.AllSamplesCollection}
            onClick={() => changeState(TabItem.AllSamplesCollection)}
          />
        </NavigationBar>
        <Row border={RowBorder.Rounded}>
          <SearchField
            key={activeTab}
            value={keyword}
            loading={loading}
            onChange={(keyword: string) => changeState(activeTab, 1, keyword)}
            placeholder="Search sample ID"
          />
        </Row>
        <Row classList="header" border={RowBorder.Top}>
          {activeTab === TabItem.SamplesToTransport && (
            <div
              className="th pointer"
              style={{ width: "5%", color: "#044554" }}
              onClick={() => {
                if (samples.length === checkedSamples.length) {
                  setCheckedSamples([]);
                } else {
                  setCheckedSamples(samples);
                }
              }}
            >
              All
            </div>
          )}
          <div className="th" style={{ width: "10%" }}>
            Sample ID{" "}
            <Sortable
              id="sample_id"
              label="Sample ID"
              type={SortType.Alphabet}
            />
          </div>
          <div className="th">
            Status{" "}
            <Sortable id="status" label="Status" type={SortType.OldestNewest} />
          </div>
          <div className="th">
            Collected From{" "}
            <Sortable
              id="collected_from"
              label="Collected From"
              type={SortType.Alphabet}
            />
          </div>
          <div className="th">
            Share Results{" "}
            <Sortable
              id="share_results"
              label="Share Results"
              type={SortType.Alphabet}
            />
          </div>
          <div className="th">
            Sample Type{" "}
            <Sortable
              id="sample_type"
              label="Sample Type"
              type={SortType.Alphabet}
            />
          </div>
          <div
            className={`th${
              activeTab === TabItem.AllSamplesCollection ? " narrow" : ""
            }`}
          >
            Date Collected{" "}
            <Sortable
              id="date_collected"
              label="Date Collected"
              type={SortType.OldestNewest}
            />
          </div>
          {/* Download column */}
          {activeTab === TabItem.AllSamplesCollection && (
            <div className="th w-100"></div>
          )}
        </Row>
        {samples.length === 0 && (
          <Row classList="full" border={RowBorder.Bottom}>
            <div className="text-center">
              {loading ? "Loading..." : "No samples to show"}
            </div>
          </Row>
        )}
        {samples.length > 0 && (
          <div
            className={`rows ${total > perPage ? "withPagination" : ""} ${
              checkedSamples.length > 0 &&
              activeTab === TabItem.SamplesToTransport
                ? "rmb"
                : ""
            }`}
          >
            {samples.map((sample: any, index: number) => {
              let onClick = (e: any) => {
                history.push(`/samples/${sample.sample_id}`, {
                  tab: activeTab,
                });
              };
              if (activeTab === TabItem.ResultsToShare) {
                onClick = () =>
                  history.push(`/samples/${sample.sample_id}/result`, {
                    tab: activeTab,
                  });
              }
              const sampleIsChecked = checkedSamples.some(
                (s) => s.sample_id === sample.sample_id
              );
              return (
                <Row
                  key={index}
                  classList={sampleIsChecked ? "pointer selected" : "pointer"}
                  border={
                    total > perPage ||
                    index < samples.length - 1 ||
                    samples.length < 5
                      ? RowBorder.None
                      : RowBorder.Bottom
                  }
                >
                  {activeTab === TabItem.SamplesToTransport && (
                    <div
                      className="td"
                      style={{ width: "5%" }}
                      onClick={() => toggleChecked(sample, !sampleIsChecked)}
                    >
                      <Checkbox
                        checked={sampleIsChecked}
                        onChange={(checked: boolean) =>
                          toggleChecked(sample, checked)
                        }
                      />
                    </div>
                  )}
                  <div
                    className="td"
                    onClick={onClick}
                    style={{ fontWeight: "bold", width: "10%" }}
                  >
                    {sample.sample_id}
                  </div>
                  <div className="td" onClick={onClick}>
                    <SampleStatus sample={sample} />
                  </div>
                  <div className="td" onClick={onClick}>
                    {getCollectedFrom(sample.collected_from)}
                  </div>
                  <div className="td" onClick={onClick}>
                    {getShareResult(sample.results_provision)}
                  </div>
                  <div className="td" onClick={onClick}>
                    {getUserFacingSampleType(sample.sample_type)}
                  </div>
                  <div
                    className={`td${
                      activeTab === TabItem.AllSamplesCollection
                        ? " narrow"
                        : ""
                    }`}
                    onClick={onClick}
                  >
                    {moment(sample.collected_at)
                      .format("MMMM DD, YYYY")
                      .toString()}
                  </div>
                  {/* Download column */}
                  {activeTab === TabItem.AllSamplesCollection && (
                    <div className="td w-100">
                      {["ANALYSIS_COMPLETE", "ANALYSIS_SHARED"].includes(
                        sample.report_state
                      ) && (
                        <button
                          className="btn-clear"
                          disabled={loading}
                          onClick={(e) => download(e, sample.sample_id)}
                        >
                          <Icons.DownloadCircle height={40} width={40} />
                        </button>
                      )}
                    </div>
                  )}
                </Row>
              );
            })}
            {samples.length < 5 && (
              <Row border={RowBorder.Bottom} classList="last" />
            )}
          </div>
        )}
        {total > perPage && (
          <Row classList="paginator" border={RowBorder.Bottom}>
            <Paginator
              onPageChange={(p: number) => changeState(activeTab, p, keyword)}
              page={page}
              perPage={perPage}
              total={total}
              disabled={loading}
            />
          </Row>
        )}
        {checkedSamples.length > 0 &&
          activeTab === TabItem.SamplesToTransport && (
            <div className="footerbanner">
              <span>{checkedSamples.length} Sample(s) Selected</span>
              <div className="buttons">
                <button
                  className="btn btn2 mr-seven"
                  onClick={() => setCheckedSamples([])}
                >
                  Clear Selection
                  <Icons.CloseBtn style={{ marginLeft: "0.5rem" }} />
                </button>
                <button
                  className="btn btn1"
                  onClick={() => {
                    history.push("/prepare-samples-for-transport", {
                      checked_samples: checkedSamples,
                      samples,
                    });
                  }}
                >
                  Prepare For Transport
                </button>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default ManageSamplesCollection;
