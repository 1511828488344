import React, {FC, FormEvent, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import Icons from '../../Icons';
import {getSampleUserService,} from '../../requests';

import UserServicePage from "../../Components/Page/userService";

const UserServiceView: FC = () => {
    const history = useHistory()

    const [sampleId, setSampleId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>();
    const [sample, setSample] = useState<any>();

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        setErrors(null);
        getSampleUserService(sampleId).then(res => {
            setSample(res.data.sample)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            if (err.message === 'Error: Not found.')
                setErrors({sample_id: 'The sample ID you have entered does not exist. Please double check your sample ID.'})
            else
                setErrors({sample_id: err.message})

        })
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (sample) {
            history.push('/service-users/result/' + sample.sample_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sample]);
    const IsDisabled: boolean = loading || sampleId.length === 0


    return (
        <UserServicePage showFooter={true} showSiteInfo={true}>
            <div className="service-user-inner">
                <header className="header"><h2 className="entry-title lg">Access a Result</h2></header>
                <div style={{paddingBottom: '22px'}}>
                    Enter the sample ID you were provided during sample collection (e.g., PD2215).
                </div>
                <form onSubmit={submitHandler}>
                    <div className={errors ? "field has-error" : 'field'}>
                        <div style={{paddingBottom: "12px"}}>Sample ID</div>
                        <input className="full" type="text" required disabled={loading} value={sampleId}
                               onChange={e => {
                                   setSampleId(e.target.value);
                                   setErrors((err: any) => ({...err, sample_id: undefined}))
                               }}/>
                        {errors && errors.sample_id &&
                            <div className="error-message">{errors.sample_id}</div>
                        }
                    </div>
                    <div className="button">
                        <button className="btn btn1" disabled={IsDisabled}>View Results <Icons.ArrowRight
                            className="chevron"/></button>
                    </div>
                </form>
            </div>
        </UserServicePage>
    );
}

export default UserServiceView;
