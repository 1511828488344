import React, {FC} from 'react';
import Icons from '../../Icons';


const TermsOfService: FC = () => {

    return (

        <section className="terms-of-service">
            <div>
                <div className="title">Terms of Service</div>
                <div className="tc-content">
                    <div className="text">
                        Though we do our very best to provide accurate information, by submitting a sample
                        to be
                        checked, the service user agrees to assume all risk as to the quality and accuracy of the
                        results and information provided.
                    </div>
                    <div className="info-link">
                        <a href="https://drugchecking.community/resource/terms-of-service/"
                           target="_blank" rel="noreferrer">
                            <Icons.ExternalLinkTC style={{marginRight: "0", padding: "0",height:"26px"}}/>
                        </a>

                    </div>
                </div>

            </div>


        </section>

    );
}

export default TermsOfService;
