import React, { FC, useEffect, useState } from 'react';

import './style.css';
import { getMyMessages } from '../../requests';
import { ucfirst } from '../../helpers';

const Banner: FC = () => {
  const [message, setMessage] = useState<any>(undefined);

  const fetchMessages = async () => {
    try {
      const data = await getMyMessages();
      setMessage(data.data);
    } catch (error) {
      setMessage(undefined);
    }
  };

  useEffect(() => {
    // Fetch messages initially
    fetchMessages();

    // Set up polling to fetch messages every 10 seconds
    const intervalId = setInterval(fetchMessages, 10000);

    // Clean up the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  return (
    message ? (
      <div style={{ backgroundColor: '#FCCAAC' }} className="message-banner">
        <div className="banner-content">
          {message.label && <div className="badge">{ucfirst(message.label)}</div>}
          <div className="message">{message.content}</div>
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

export default Banner;
