    import React, {FC} from 'react';

import './style.css';

type IProps = {
    sample: any;
    report: any;

}

const TestStrip: FC<IProps> = (props: IProps) => {
    function capitalizeFLetter(input:string) {
        const string = input.toLocaleLowerCase()
        return string[0].toUpperCase() +
            string.slice(1);
    }


    return (

        <>
            <div className="analysis-technology-details">
                <h6>Test Strip</h6>
                <div className="row3col">
                    <div className="field">
                        <div className="text">Test Strip Type:</div>
                        <div className="bold mb">{props.report.strip_type}</div>
                    </div>
                    <div className="field">
                        <div className="text">Test Strip Result:</div>
                        <div
                            className="bold mb">{capitalizeFLetter(props.report.strip_result)}</div>
                    </div>

                </div>
                {props.report.tech_notes && <div className="row1col">
                    <div className="field">
                        <div className="text">Tech Notes</div>
                        <div className="bold mb">{props.report.tech_notes}</div>
                    </div>
                </div>}
            </div>

        </>


    );
}

export default TestStrip;
