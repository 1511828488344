import React, {FC} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import DisposeSample from '.';
import { Page } from '../../Components';
import { TabItem } from '../../types';

type ILocation = {
    state: any;
    sample?: string;
}

const DisposeSampleRoute: FC = () => {
    const {state} = useLocation<ILocation>();
    const history = useHistory();

    let onCancel = undefined;
    if (state.sample) {
        onCancel = () => {
            history.push(`/result/${state.sample}`);
        }
    }

    const back = state.sample ?  undefined : TabItem.SamplesToDispose;

    return (
        <Page full title="Confirm Sample Disposal" back={back} onCancel={onCancel}>
            <DisposeSample />
        </Page>
    );
}

export default DisposeSampleRoute;
