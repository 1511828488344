import React, {FC} from 'react';

import './style.css';
import DrugsFound from "./DrugsFound";

type IProps = {
    sample: any;
    report: any;
    performs_collection:boolean;

}

const FTIR: FC<IProps> = (props: IProps) => {
const report = props.report

    return (

        <>
            <div className="analysis-technology-details">
                <h6>FTIR (Fourier transform infrared spectroscopy)</h6>
                <DrugsFound
                    isDraft={report && report.is_draft}
                    drugs={report.drugs_found ?? []}
                    weight={report.weight * 1000}
                    isLiquid={report && report.is_liquid}
                    performsCollection={props.performs_collection}
                />
                {
                    report.tech_notes && <div className="row1col">
                        <div className="field">
                            <div className="text">Tech Notes</div>
                            <div className="bold mb">{report.tech_notes}</div>
                        </div>
                    </div>
                }

                {report.notes && <div className="drugnotes">
                    <div>Public Notes</div>
                    <p>{report.notes}</p>
                </div>}
            </div>

        </>


    );
}

export default FTIR;
