import React, {FC, useContext, useState} from 'react';
import { useHistory } from 'react-router';
import { Fullname } from '../../Components';

import { deleteSample } from '../../requests';
import { StateContext } from '../../state';

type IProps = {
    sample_id: string;
}

const RequestDeleteSample: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [reason, setReason] = useState<string>('');

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setLoading(true);
            await deleteSample(props.sample_id, name, reason);
            dispatch({type: "showAlert", payload: {
                message: "Request to delete sent",
                status: `Sample ID: ${props.sample_id}`,
            }});
            history.goBack();
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="field">
                <label className="label">Full name of staff requesting sample deletion: <span className="required">*</span></label>
                <Fullname disabled={loading} onChange={(fullname: string) => setName(fullname)} />
            </div>
            <div className="field">
                <label className="label">
                    Why do you want to delete this sample?
                    <span className="required">*</span>
                </label>
                <textarea
                    style={{width: '100%'}}
                    disabled={loading}
                    required 
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                />
            </div>
            <div className="field">
                <button className="btn btn1 ml-auto" disabled={loading || !name || !reason}>Submit Request</button>
            </div>
        </form>
    );
}

export default RequestDeleteSample;
