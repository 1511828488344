import React, {FC, useContext} from 'react';
import { useHistory } from 'react-router-dom';

import { StateContext } from '../../state';
import Icons from '../../Icons';
import './style.css';

const Sidebar: FC = () => {
    const {dispatch} = useContext(StateContext);
    const history = useHistory();

    return (
        <div className="sidebar">
            <div className="logodiv">
                <Icons.SidebarLogo className="logo" />
                <hr />
            </div>
            <ul className="items">
                <li className="active" onClick={() => history.push('/')}>
                    <Icons.BarChart />
                </li>
            </ul>
            <div className="bottom">
                <hr />
                <ul className="items">
                    <li style={{marginBottom: '1.4375rem'}} onClick={() => {
                        dispatch({type: 'logout'});
                        history.replace("/");
                    }}>
                        <Icons.Logout />
                    </li>
                </ul> 
            </div>
        </div>
    );
}

export default Sidebar;
