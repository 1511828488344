import React, {FC, useEffect, useState} from 'react';

import './style.css';

type IProps = {
    onChange: (fullname: string) => void;
    disabled?: boolean;
    onName?: (firstName: string, lastName: string) => void;
    name?: {firstName: string, lastName: string};
}

const Fullname: FC<IProps> = (props: IProps) => {
    const [firstName, setFirstName] = useState<string>(props.name ? props.name.firstName : '');
    const [lastName, setLastName] = useState<string>(props.name ? props.name.lastName : '');
    useEffect(() => {

        if (props.name?.firstName ){
            setFirstName(props.name?.firstName??"")}
        if (props.name?.lastName ){

            setLastName(props.name?.lastName??"")
        }

    }, [props.name]);
    useEffect(() => {

        if (firstName.length > 0 && lastName.length > 0) {
            props.onChange(`${firstName} ${lastName}`)
            props.onName && props.onName(firstName, lastName);
        } else {
            props.onChange('');
            props.onName && props.onName('', '');
        }
        // eslint-disable-next-line
    }, [firstName, lastName])

    return (
        <div className="fullname">
            <input
                type="text"
                placeholder="First name"
                required
                value={firstName}
                disabled={!!props.disabled}
                onChange={(event) => setFirstName(event.target.value)}
            />
            <input
                type="text"
                placeholder="Last name"
                required
                value={lastName}
                disabled={!!props.disabled}
                onChange={(event) => setLastName(event.target.value)}
            />
        </div>
    );
}

export default Fullname;
