import React, {FC} from 'react';
import Icons from '../../Icons';

import './style.css';

type IProps = {
    label?: string;
    on: boolean;
    onToggle: (status: boolean) => void;
}

const ToggleSwitch: FC<IProps> = (props: IProps) => {
    const Icon = props.on ? Icons.SwitchOn : Icons.SwitchOff;

    return (
        <div className="toggleswitch">
            <Icon onClick={() => props.onToggle(!props.on)} />
            {props.label &&
                <span>{props.label}</span>
            }
        </div>
    );
}

export default ToggleSwitch;
