import React, {FC, useReducer} from 'react';
import {BrowserRouter, Route} from "react-router-dom";

import {initialState, reducer, StateContext} from './state';
import {Notifications} from './Components';
import {CourierRoutes, Login} from './Pages';
import {AnalysisRoutes, CollectionRoutes} from './Pages/Routes';

import './style.css';
import {useToken} from './helpers';
import UserServiceRoutes from "./Pages/Routes/userService";

const App: FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useToken();

    return (
        <StateContext.Provider value={{state, dispatch}}>
            <BrowserRouter>
                <Route path="/routes" exact>
                    <CourierRoutes/>
                </Route>
                
                <UserServiceRoutes/>

                {state.user &&
                    <>
                        {state.user.performs_collection && <CollectionRoutes/>}
                        {state.user.performs_analysis && <AnalysisRoutes/>}
                    </>
                }
                {!state.user &&
                    <Route path="/" exact>
                        <Login/>
                    </Route>
                }
            </BrowserRouter>
            <Notifications/>
        </StateContext.Provider>
    );
}

export default App;
