import React, {FC} from 'react';

import { RowBorder } from '../../types';
import './style.css';

type IProps = {
    children?: any;
    onClick?: Function;
    classList?: string;
    border: RowBorder;
}

const Row: FC<IProps> = (props: IProps) => {
    let classNames = ["row", `border-${props.border}`];
    if (props.onClick) {
        classNames.push("pointer");
    }

    return (
        <div className={`${classNames.join(" ")} ${props.classList ? props.classList : ''}`} onClick={e => {if (props.onClick) {props.onClick(e)}}}>
            <div className="rowcontent">
                {props.children}
            </div>
        </div>
    );
}

export default Row;
