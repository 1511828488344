import React, {FC} from 'react';

import './style.css';

type IProps = {
    children: any
}

const NavigationBar: FC<IProps> = (props: IProps) => {
    return (
        <ul className="navbar">
            {props.children}
        </ul>
    );
}

export default NavigationBar;
