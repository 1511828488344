import React, {FC} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import RequestDeleteSample from '.';
import Page from '../../Components/Page';

const RequestDeleteSampleRoute: FC = () => {
    const {sample_id} = useParams<any>();
    const history = useHistory();

    return (
        <Page full title="Request To Delete Sample" sample_id={sample_id} onCancel={() => history.goBack()}>
            <RequestDeleteSample sample_id={sample_id} />
        </Page>
    );
}

export default RequestDeleteSampleRoute;
