import React, {FC, useEffect, useState, useCallback, useContext} from 'react';
import moment from 'moment';

import { CourierRoute } from '../../Components';
import { getRoutes } from '../../requests';
import Icons from '../../Icons';
import BackgroundImage from '../../Icons/crtbackground.png';
import { StateContext } from '../../state';
import './style.css';

type Route = {
    list_order: number;
    source: string;
    dest: string;
}

const CourierRoutes: FC = () => {
    const {dispatch} = useContext(StateContext);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [debugMode, setDebugMode] = useState<boolean>(false);
    const [cutoffTime, setCutoffTime] = useState<string[]>();
    const [notes, setNotes] = useState<string|null>();
    const [phone, setPhone] = useState<string|null>();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setDebugMode(params.get("debug") === "1")
    }, [])

    const getRoutesFunc = useCallback(async () => {
        try {
            const {data: {routes, notes, phone}} = await getRoutes(cutoffTime)
            routes.sort((a: Route, b: Route) => a.list_order - b.list_order)
            setRoutes(routes)
            setPhone(phone)
            setNotes(notes)
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        }
    }, [dispatch, cutoffTime]);

    useEffect(() => {
        getRoutesFunc();
        const refresh = setInterval(getRoutesFunc, 10000);
        return () => clearInterval(refresh);
    }, [getRoutesFunc])

    return (
        <div className="courierroutes">
            <div className="topbar">
                <Icons.CourierLogo />
            </div>
            <div className="crheader" style={{ 
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: '100%'
            }}>
                <div className="crcontent">
                    <h1>Samples To Transport</h1>
                    <div className="date">{moment().format("MMMM D, YYYY")}</div>
                    {debugMode &&
                        <div>
                            <hr />
                            <label htmlFor="time">
                                Cutoff time: 
                                <input id="time" type="time" value={cutoffTime ? `${cutoffTime[0]}:${cutoffTime[1]}` : "10:30"} onChange={e => {
                                    const time = e.target.value
                                    setCutoffTime(time.split(":"))
                                }}/>
                            </label>
                        </div>
                    }
                </div>
            </div>
            <div className="locations crcontent">
                <h2>Pickup Locations:</h2>
                {routes.map((route: any, index: number) => {
                    return (
                        <div key={index} style={{marginBottom: '1.375rem'}}>
                            <CourierRoute dropoff={route.destination} pickup={route.source} />
                        </div>
                    )
                })}
            </div>
            {notes &&
                <div className="notes crcontent">
                    <h4>Notes:</h4>
                    <p>{notes}</p>
                </div>
            }
            <div className="crfooter">
                <p>
                    Need help?<br />
                    <br />
                    Contact an administrator at:<br />
                    {phone &&
                        <div className="contact"><Icons.Phone /> <a href={`tel:${phone}`}>{phone}</a></div>
                    }
                    <br />
                    <div className="contact"><Icons.Mail /> <a href="mailto:hello@drugchecking.community">hello@drugchecking.community</a></div>
                </p>
            </div>
        </div>
    )
}

export default CourierRoutes;
