import { useEffect } from 'react';

function useOnClickOutside(ref: any, handler: Function) {
    useEffect(
        () => {
            const listener = (event: any) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };

            const keyDownHandler = (event: any) => {          
                if (event.key === 'Escape') {
                  event.preventDefault();
                  handler(event)
                }
            };

            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            document.addEventListener('keydown', keyDownHandler);

            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
                document.removeEventListener('keydown', keyDownHandler);
            };
        },
        [ref, handler]
    )
}

export {
    useOnClickOutside
};
