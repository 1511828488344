import React, { FC } from 'react'

type IProps = {
    isQuantified: boolean;
    isSemiQuantified: boolean;
    noDrugsFound: boolean;
    oneDrugFound: boolean;
    hasIndicator: boolean;
}

const Preamble: FC<IProps> = (props: IProps) => {
    return (
        <div className="cta">
            <p>
                {props.hasIndicator &&
                    <>
                        {props.isQuantified &&
                            <>
                                This is a comprehensive list of the substances found in this sample. Depending on the technology used, the substances found could include drugs and non-drug fillers. <b>We can provide precise information about the amount of some substances found in this sample. </b> We’ve done our best to list all other substances found from most to least present.
                                
                            </>
                        }
                        {props.isSemiQuantified &&
                            <>
This is a comprehensive list of the substances found in this sample. Depending on the technology used, the substances found could include drugs and non-drug fillers. <b>While we cannot provide precise information about the amount of each substance found, we’ve done our best to list substances found from most to least present.</b>
                            </>
                        }
                    </>
                }
                {!props.hasIndicator &&
                    <>
                        
                        {props.isQuantified &&
                            <>
                                This is a comprehensive list of the substances found in this sample. Depending on the technology used, the substances found could include drugs and non-drug fillers. <b>We can provide precise information about the amount of some substances found in this sample. </b> We’ve done our best to list all other substances found from most to least present.
                            </>
                        }
                        {props.oneDrugFound &&
                            <>
                                This is a comprehensive list of the substances found in this sample. Depending on the technology used, the substances found could include drugs and non-drug fillers. <b>We can provide precise information about the amount of some substances found in this sample. </b> We’ve done our best to list all other substances found from most to least present.
                            </>
                        }
                    </>
                }
            </p>
        </div>
    )
}

export default Preamble