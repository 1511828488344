import React, {FC} from 'react';
import { Route, Switch } from 'react-router-dom';
import UserServiceView from "../UserService";
import UserserviceResultView from "../UserService/Result";
import ShareDetailView from "../UserService/share";



const UserServiceRoutes: FC = () => {
    return (
        <Switch>

            <Route path="/service-users" exact>
                <UserServiceView/>

            </Route>
            <Route path="/service-users/result/:sample_id" exact>
                <UserserviceResultView/>

            </Route>
            <Route path="/service-users/share/:sample_id" exact>
                <ShareDetailView/>

            </Route>
        </Switch>
    );
}

export default UserServiceRoutes;
