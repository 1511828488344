
import React, {FC} from 'react';
import { Checkbox } from '..';
import { Option } from '../../types';

type IProps = {
    question: string;
    name: string;
    updateItems: (name: string, item: any, action: 'push' | 'remove') => void;
    options: Option[];
    form: any;
    required?: boolean;
}

const CheckboxField: FC<IProps> = (props: IProps) => {    
    return (
        <div className="field">
            <label className="label">{props.question} {props.required && <span className="required">*</span>}</label>
            {props.required && 
                <input type="text" tabIndex={-1} required className="hidden" value={props.form ? '1' : undefined} />
            }
            {props.options && props.options.map((item: Option, index: number)=>{
                const checked = props.form && props.form.length > 0 ? props.form.find((t: any) => t.id === item.id) : false;
                
                let label = item.main ? item.main : item.text;
                if (!!checked && item.other) {
                    label = `${label}:`
                }

                return (
                    <div className="mb-1" key={index}>
                        <Checkbox
                            key={index}
                            checked={!!checked}
                            onChange={value => props.updateItems(props.name, {...item, text: checked ? checked.text : item.text, main: item.text}, value ? 'push' : 'remove')}
                            label={label}
                        />
                        <input 
                            placeholder="Please specify *" 
                            style={{display: !!checked && item.other ? 'block' : 'none'}}
                            type="text"
                            required={!!checked && item.other}
                            className="mt-1 other"
                            value={!!checked && checked.other && checked.main !== checked.text ? checked.text : undefined}
                            onChange={e => {
                                const text = e.target.value;
                                if (!!checked && item.other) {
                                    props.updateItems(
                                        props.name, 
                                        {...item, text, main: item.main ? item.main : item.text
                                        },
                                        'push'
                                    );
                                }
                            }}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default CheckboxField;
