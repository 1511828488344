export enum SampleStatuses {
    AwaitingTransport,
    InTransit,
    UndergoingAnalysis,
    FurtherAnalysis,
    DeletionRequested,
    Missing,
    ResultsAvailable,
    ResultsReported,
    ResultsShared,
    Disposed,
    NotAnalyzed,
    CouldNotShare,
}

export type Option = {
    id: number | string;
    order?: number;
    text: string;
    other?: boolean;
    main?: string;
    alias?: string;
}

export type TechnologyData = {
    hasError:boolean;
    id: number | string;
    technology: string;
    data?: any;

}

export enum TabItem {
    SamplesToTransport,
    ResultsToShare,
    AllSamplesCollection,
    AllSamplesAnalysis,
    SamplesToReceive,
    SamplesToReport,
    SamplesToDispose,
    AnalysisResult,
    CollectionData,
}

export type DrugFound = {
    weight?: string | number,
    percent?: string | number,
    drug?: {
        id: string;
        name: string;
        important: boolean;
        report_to_public: boolean;
        alias?: string;
    },
    custom_drug?: string;
}

export enum RowBorder {
    Rounded,
    Top,
    Bottom,
    None
}

export type Count = {
    to_transport: number,
    to_share: number,
    to_receive: number,
    to_analyze: number,
    to_dispose: number,
}


export enum SortType {
    Alphabet,
    OldestNewest
}

export enum SortOrder {
    ASC,
    DESC
}

// This enum was introduced late into dev so there may be many areas in the code where sample type is being referenced without this type.
export enum SampleType {
    PARAPHERNALIA = 'PARAPHERNALIA',
    SUBSTANCE = 'SUBSTANCE',
}

export enum ContainerType {
    VIAL = 'VIAL',
    BAG = 'BAG',
}
export type CustodyLog = {
    action: string;
    staff: string;
    site: any;
    edited_at: string
    track_number?: string
    time: string
}



export interface SamplePackageDictionary{
    [index: string]:any
}
export interface CustodyLogDictionary {
    [index: string]: CustodyLog;
}