import React, {FC, useContext, useState, useCallback, useEffect} from 'react';

import Icons from '../../Icons';
import { StateContext } from '../../state';
import './style.css';

const Notifications: FC = () => {
    const {state, dispatch} = useContext(StateContext);
    const [hide, setHide] = useState<boolean>(false);

    const hideAlert = useCallback(() => {
        setHide(true);
        return setTimeout(() => {
            setHide(false);
            dispatch({type: 'alertShown'});
        }, 500);
    }, [dispatch])

    useEffect(() => {
        if (!state.alert || hide === true) return;
        const timer = setTimeout(hideAlert, 3000)
        return () => {
            clearTimeout(timer)
        }
    }, [hide, state.alert, hideAlert])

    if (!state.alert) return null;

    const Icon = state.alert.error ? Icons.Alert : Icons.Check;

    return (
        <div style={{backgroundColor: state.alert.error ? '#C33F38' : '#1E9E09'}} className={`notification ${hide ? 'hide' : 'show'}`} onClick={() => {
            if (state.alert.view) {
                state.alert.view();
            }
            hideAlert();
        }}>
            <Icon className="icon" />
            <div className="content">
                <div className="message">{state.alert.message}</div>
                {state.alert.status &&
                    <div className="status">{state.alert.status}</div>
                }
            </div>
            {state.alert.view &&
                <div className="view" onClick={() => {
                    state.alert.view();
                    hideAlert();
                }}>View</div>
            }
        </div>
    );
}

export default Notifications;
