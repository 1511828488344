import React, {FC, useState} from 'react';
import Icons from '../../Icons';

import './style.css';

type IProps = {
    placeholder?: string;
    value?: string;
    onChange: (keyword: string) => void;
    loading?: boolean;
}

const SearchField: FC<IProps> = (props: IProps) => {
    const [keyword, setKeyword] = useState<string | undefined>(props.value);

    const submitHandler = (e: any) => {
        e.preventDefault()
        if (keyword) {
            props.onChange(keyword)
        }
    }

    return (
        <form className="sfcontainer" onSubmit={submitHandler}>
            <Icons.Search className="sficon" />
            <input
                type="search"
                className="searchfield"
                placeholder={props.placeholder}
                value={keyword}
                onChange={e => {
                    setKeyword(e.target.value)
                    if (e.target.value === '') {
                        props.onChange('')
                    }
                }}
                disabled={props.loading}
            />
        </form>
    );
}

export default SearchField;
