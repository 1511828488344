import React, {FC, useCallback, useEffect, useState} from 'react';
import {describeAction} from '../../helpers';
import {getCustodyLogs} from '../../requests';
import './style.css';
import {CustodyLog, CustodyLogDictionary} from "../../types";

type IProps = {
    sample_id: string;
};

const CustodyLogs: FC<IProps> = (props: IProps) => {
    const [data, setData] = useState<CustodyLog[] | null>(null);
    const [error, setError] = useState<string>('');

    const fetchData = useCallback(async () => {
        try {
            setError('');
            const { data } = await getCustodyLogs(props.sample_id);
            const groupedData = data.reduce((acc:CustodyLogDictionary, log:CustodyLog) => {
                const key = `${log.action}-${log.site.id}${log.action === "ANALYZED" ? '' : '-' + log.time}`;

                acc[key] = acc[key] || {};
                log.staff = (acc[key].staff || '') + (acc[key].staff ? '||' : '') + log.staff;
                acc[key] = {...acc[key], ...log};
                return acc;
            }, {} as CustodyLogDictionary);

            const sortedData  = Object.values<CustodyLog>(groupedData).sort((a:CustodyLog,b:CustodyLog)=>a.time>b.time?-1:(a.time<b.time?1:0))
            setData(sortedData);
        } catch (err:any) {
            setError(err.message);
        }
    }, [props.sample_id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (error) {
        return (
            <div>
                <h2>Error: {error}</h2>
                <button className="btn btn2" onClick={fetchData}>
                    Try Again
                </button>
            </div>
        );
    }

    if (!data) {
        return <h2>Loading...</h2>;
    }

    return (
        <ul className="custodylogs">
            {data.map((log: CustodyLog, index: number) => (
                <li key={index}>
                    <div className="status">{describeAction(log.action)} By:</div>
                    <div className="value">
                        {log.track_number
                            ? 'Shipment tracking number: ' + log.track_number
                            : log.staff.split('||').map((item: string) => (
                                <span key={item}>
                    {item}
                                    <br />
                  </span>
                            ))}
                    </div>
                    {log.site && log.action !== 'DELIVERED' && (
                        <div className="value">
                            {log.site.name}
                            <br />
                            {log.site.address}
                        </div>
                    )}
                    {log.time && (
                        <div className="date">
                            {new Date(log.time).toLocaleString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true,
                            })}
                        </div>
                    )}
                    {log.edited_at && (
                        <div className="date">
                            Edited: {new Date(log.edited_at).toLocaleString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true,
                        })}
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default CustodyLogs;
