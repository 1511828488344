import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import { Checkbox, Fullname } from '../../Components';
import { getUserFacingSampleType } from '../../helpers';
import { confirmReceiptSamples } from '../../requests';
import { StateContext } from '../../state';

import './style.css';
import { SamplePackageDictionary } from '../../types';

type IProps = {
    checkedSamples: any[];
    onDone: (checkedSamples: any[], fullname?: string) => void;
    onLoading: (status: boolean) => void;
}

const ConfirmReceiptSamples: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
    const [courierName, setCourierName] = useState<string>('');
    const [fullname, setFullname] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [packages, setPackages] = useState<SamplePackageDictionary>([]);


    useEffect(() => {
        props.onLoading(loading);
    }, [loading, props])


    useEffect(() => {
        setCheckedSamples(props.checkedSamples);

        const newPackages: SamplePackageDictionary = [];
        props.checkedSamples.forEach((item) => {
          const trackNumber = item.track_number;
          if (trackNumber) {
            newPackages[trackNumber] = newPackages[trackNumber] || [];
            newPackages[trackNumber].push(item);
          } else {
            newPackages.empty = newPackages.empty || [];
            newPackages.empty.push(item);
          }


        });
        setPackages(newPackages);

        if (!newPackages.empty || newPackages.empty.lenght===0){
            setPage(2)
            
        }else
        setPage(1)
        
        
      }, [props.checkedSamples]);

    const submitHandler = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (checkedSamples.length === 0) {
            props.onDone([], fullname);
            return;
        }
        try {
            setLoading(true);
            
            if(page===2){
                
                const internal_samples_ids = packages.empty?packages['empty'].map((item:any)=>item.sample_id):[]
                const samples=checkedSamples.filter((obj)=>!internal_samples_ids.includes(obj.sample_id));
                              
                await confirmReceiptSamples(samples.map(cs => cs.sample_id), '', fullname);
                props.onDone(checkedSamples, fullname);
            }
                
            else{
                await confirmReceiptSamples(packages['empty'].map((cs:any) => cs.sample_id), courierName, fullname);
                const not_empty = Object.keys(packages).filter((item:string)=>item!=='empty').length
                if(not_empty===0){
                    props.onDone(checkedSamples, fullname);
                }else
                {
                    setPage(2)
                setConfirm(false)
                }
                
            }
                
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedSamples, courierName, fullname, dispatch, props])
    
    const IsDisabled = (page===1 && !courierName) || !fullname || !confirm;
    return (
        <form className="receiptsamples" onSubmit={submitHandler}>
            <div className="field">
                <label className="label">
                    Confirm that the samples below are present. Uncheck any samples that cannot be accounted for. 
                </label>
                {Object.keys(packages??[]).map((item:any)=>
               {       
                if(item==='empty' && page===1)
               return <div className="box ">
  
                    {packages[item].map((sample: any, index: number) => {
                        return (
                            <div key={index} className="boxrow">
                                <Checkbox
                                    checked={!!checkedSamples.find(cs => cs.sample_id === sample.sample_id)}
                                    onChange={(s: boolean) => {
                                        const item = checkedSamples.find(cs => cs.sample_id === sample.sample_id);
                                        if (item && !s) {
                                            setCheckedSamples(os => os.filter(o => o.sample_id !== item.sample_id));
                                        } else if (!item && s) {
                                            setCheckedSamples(os => [...os, sample]);
                                        }
                                    }} 
                                />
                                <div className="boxsample" style={{marginRight: '4.75rem'}}>
                                    <span className="sampleidlabel">Sample ID:</span>
                                    <span className="sampleid">{sample.sample_id}</span>
                                </div>
                                <div className="boxsample">
                                    <span className="sampleidlabel">Sample type:</span>
                                    <span className="sampleid">{getUserFacingSampleType(sample.sample_type)}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            
            else if(page===2 && item!=='empty')
                return <div className="box ">
                                  <div className='shippingheader'>
                    <div className='shippingtitle' >
                    Shipment Tracking Number:
                    </div>
                    <div>
                        {item}
                    </div>

                    </div>
                     {packages[item].map((sample: any, index: number) => {
                         return (
                             <div key={index} className="boxrow">
                                 <Checkbox
                                     checked={!!checkedSamples.find(cs => cs.sample_id === sample.sample_id)}
                                     onChange={(s: boolean) => {
                                         const item = checkedSamples.find(cs => cs.sample_id === sample.sample_id);
                                         if (item && !s) {
                                             setCheckedSamples(os => os.filter(o => o.sample_id !== item.sample_id));
                                         } else if (!item && s) {
                                             setCheckedSamples(os => [...os, sample]);
                                         }
                                     }} 
                                 />
                                 <div className="boxsample" style={{marginRight: '4.75rem'}}>
                                     <span className="sampleidlabel">Sample ID:</span>
                                     <span className="sampleid">{sample.sample_id}</span>
                                 </div>
                                 <div className="boxsample">
                                     <span className="sampleidlabel">Sample type:</span>
                                     <span className="sampleid">{getUserFacingSampleType(sample.sample_type)}</span>
                                 </div>
                             </div>
                         )
                     })}
                 </div>
                   else return  <></>
            }
                )
                }
            </div>
            {page===1 && <div className="field">
                <label className="label">
                    Full name of person who transported sample(s): <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setCourierName(fullname)} />
            </div>}
            <div className="field">
                <label className="label">
                    Full name of staff that is accepting sample(s): <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setFullname(fullname)} />
            </div>
            <div className="field">
                <Checkbox
                    required={true}
                    checked={confirm} 
                    onChange={(s: boolean) => setConfirm(s)}
                    label="I confirm that the sample(s) are accounted for"
                />
            </div>
            <div className="field">
                <button disabled={IsDisabled} type="submit" className="btn btn1">Submit</button>
            </div>
        </form>
    );
}

export default ConfirmReceiptSamples;
