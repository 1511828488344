import React, {FC} from 'react';

import Icons from '../../Icons';
import './style.css';

type IProps = {
    label?: string;
    checked: boolean;
    onChange: (checked: boolean) => any;
    disabled?: boolean;
    children?: any;
    required?: boolean;
}

const Checkbox: FC<IProps> = (props: IProps) => {
    const Icon = props.checked ? Icons.CheckboxActive : Icons.CheckboxInactive;

    return (
        <>
            <label 
                tabIndex={0} 
                onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === "Enter" || e.code === 'Space') {
                        if (!props.disabled) {
                            props.onChange(!props.checked);
                        }
                    }
                }}  
                className={`checkbox ${props.disabled ? 'disabled' : ''}`}
                onClick={(event: any) => {
                    if (event.target.tagName !== 'A' && !props.disabled) {
                        props.onChange(!props.checked);
                        event.stopPropagation();
                    }
                }}
            >
                <Icon/> <span>{props.label || props.children} {props.required && <span className="required">*</span>}</span>
            </label>
            {props.required &&
                <input type="text" tabIndex={-1} required value={props.checked ? '1' : undefined} className="hidden" />
            }
        </>
    );
}

export default Checkbox;
