import React, {FC} from 'react';

import './style.css';

type IProps = {
    label: string;
    count?: number;
    active?: boolean;
    onClick?: () => any;
}

const NavigationTab: FC<IProps> = (props: IProps) => {
    let classes: string[] = [];

    if (props.active) classes.push('active');
    if (props.onClick) classes.push('pointer');

    const hasBadge = props.count !== undefined && props.count > 0;

    return (
        <li className={classes.join(' ')} onClick={() => {
            if (props.onClick) {
                props.onClick();
            }
        }}>
            <span className={`tablabel ${hasBadge ? 'margin' : ''}`}>{props.label}</span>
            {hasBadge &&
                <span className="badge">{props.count}</span>
            }
        </li>
    );
}

export default NavigationTab;
